.quicklink {
    width: 360px;
    height: 160px;
    border: 2px solid rgba(0,0,0, .9);
    border-radius: 20px;
    margin: 30px;
    transition: all 0.11s ease-in-out;
    background-color: rgba(255, 255, 255, .9);
}

.quicklink:hover {
    transform: translateY(-8px);
}

.quicklink > a {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.quicklink a * {
    margin: 0.5em;
}

.quicklink > a > p {
    padding-bottom: 2em;
}

.quicklinkheader {
    display: flex;
    align-items: center;
}

.quicklinkheader > img {
    height: 45px;
    width: 45px;
    margin: 0 6px 0 2px;
    padding: 8px;
    border-radius: 8%;
}

.quicklink > a > p {
    text-align: center;
    color: var(--dark-text-color);
}

.quicklinkheader > h3 {
    font-size: 1.7rem;
    color: var(--dark-text-color);
}