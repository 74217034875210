main {
    padding: 0 2rem;
}

.quicklinks ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

/* .projectsGrid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}

.card li,
.card p,
.card h4 {
    color: var(--dark-text-color);
}

.card {
    margin: 16px !important;
    /* display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important; 
    max-width: 600px !important;
}

.cardMedia {
    height: 280px;
}

.cardActions {
    margin-top: auto !important;
}
    */