header {
    /* max-width: min(85%, 75rem);
    background-color: var(--background-color); */
    margin: 0 auto;
    padding: 0 2rem;
    display: grid;
    grid-template-areas: 
        "heading headshot"
        "subheading headshot";
}

@media only screen and (max-width: 900px) {
    header {
        grid-template-areas:
            "heading"
            "subheading"
            "headshot"
    }
  }

header > h1 {
    grid-area: heading;
    min-width: 50%;
    font-size: 3rem;
}

header > p  {
    font-size: 1.25rem;
}

header > p > br {
    padding-top: 16px;
}

header > img {
    grid-area: headshot;
    border-radius: 50%;
    width: min(20rem, 100%);
    height: min(20rem, 100%);
    /* padding: 3em; */
    margin: 0 auto;
    object-fit: scale-down;
    /* Loading shimmer */
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    background-size: 300%;
    background-position-x: 100%;
    animation: shimmer 1s infinite ease-in-out;
}

@keyframes shimmer {
    to {
        background-position-x: 0%;
    }
}
