:root {
  --background-color: #201E1D;
  --light-gray: #ADADAD;
  --light-text-color: #EEEEEE;
  --dark-text-color: #333333;
  --button-background-color: rgb(25, 118, 210);
  font-size: 18px;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--light-text-color);
  font-size: 2rem;
}

p, li, a {
  color: var(--light-gray);
}

.bg {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: url(../public/img/binary-repeat.png);
  filter: invert(1) brightness(0.4);
  transform: scale(1.3, 1.3);
  animation: swipe 120s linear infinite;
}

.content {
  max-width: min(95%, 100rem);
  background-color: var(--background-color);
  margin: 0 auto;
}

@keyframes swipe {
  0% {
      background-position: 0% 50%;
      /* filter: invert(1) brightness(0.4); */
  }

  50% {
      background-position: 100% 50%;
      /* filter: invert(1) brightness(0.4); */
  }

  100% {
      background-position: 0% 50%;
      /* filter: invert(1); */
  }
}