.projectCardButton {
    background-color: var(--button-background-color);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    border-radius: 12px;
}

.projectCardButton:hover {
    transition: all 0.11s ease-in-out;
    transform: translateY(-6px);
}

.projectCardButton > a {
    height: 100%;
    margin: 0.5em;
    display: flex;
    align-items: center;
    text-decoration: none; /* Hide text underline */
    text-transform: capitalize;
    font-weight: 600; /* bold */
}

.projectCardButton img {
    width: 1.3em;
    height: 1.3em;
    filter: invert(100%);
}

.projectCardButton > a > p {
    color: var(--light-text-color);
    padding: 1px 0.5em;
}